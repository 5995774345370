@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'kittithada';
  src: url('/fonts/kittithada/kittithadalight45-webfont.woff2') format('woff2'),
    url('/fonts/kittithada/kittithadalight45-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'kittithada65';
  src: url('/fonts/kittithada/kittithadamedium65-webfont.woff2') format('woff2'),
    url('/fonts/kittithada/kittithadamedium65-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'kittithada75';
  src: url('/fonts/kittithada/kittithadabold75-webfont.woff2') format('woff2'),
    url('/fonts/kittithada/kittithadabold75-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'pricesign';
  src: url('/fonts/twdprice/hw-pricesign-webfont.woff2') format('woff2'),
    url('/fonts/twdprice/hw-pricesign-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.pac-container {
  z-index: 9999 !important; /* Set the desired z-index */
}

.font-kitti {
  font-family: 'kittithada' !important;
}

.font-semibold {
  font-family: 'kittithada65' !important;
  font-weight: 500 !important;
}

.font-bold {
  font-family: 'kittithada75' !important;
  font-weight: 500 !important;
}

.font-price {
  font-family: 'pricesign' !important;
}

.lh-1 {
  line-height: 1 !important;
}

:root {
  /* --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255; */
  font-family: 'kittithada';
  font-size: 24px;
  word-spacing: -0.15rem !important;
}

/* 
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
  background-color: #f1f1f1;
}

.disable-scroll {
  overflow: hidden !important;
}

.max-width-container {
  max-width: 1360px !important;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.overlayload {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

/* Loading Animation */

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  background: #b8292f;
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  background: #f1f1f1;
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  background: #e4a3a3;
  left: 56px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: auto;
}

.lds-ellipsis div {
  position: absolute;
  top: 1rem;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  background: #b4b4b4;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  background: #e4a3a3;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  background: #b8292f;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* Loading Animation */

.content-body {
  /* 100vh - TopHeader - CenterHeader - BottomHeader - TopFooter - CenterFooter - BottomFooter */
  min-height: calc(100vh - 1.5rem - 3rem - 2rem - 16.75rem - 1.5rem);
  max-width: 1360px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.h-content {
  min-height: calc(100vh - 1.5rem - 3rem - 2rem - 16.75rem - 1.5rem);
}

.slick-prev:before,
.slick-next:before {
  color: #b8292f !important;
}

.tooltip-arrow .MuiTooltip-arrow {
  color: white !important;
}

.tooltip-arrow .MuiTooltip-arrow::before {
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.2) !important;
}

.text-xxs {
  font-size: 0.625rem !important;
}

.text-xxxs {
  font-size: 0.5rem !important;
}

.swal2-title {
  padding-top: 0 !important;
  padding-bottom: 0.5rem !important;
}

.swal2-container {
  z-index: 99999 !important;
}

.swal2-popup {
  padding-top: 1rem !important;
  padding-bottom: 0.5rem !important;
}

.swal2-html-container,
.swal2-actions,
.swal2-icon {
  margin-top: 0 !important;
}

.swal2-actions > button {
  padding: 0.25rem 1rem !important;
}

.swal2-close {
  font-size: 1.5rem;
  width: auto;
  box-shadow: none !important;
}

.addtocart-text,
.addtocart-text-xs {
  display: -webkit-box !important;
}

.addtocart-plus,
.addtocart-plus-xs {
  display: none !important;
}

.cloned-product-image {
  position: absolute;
  opacity: 0.5;
  z-index: 9999;
}

.main-cart.shake {
  animation: shake 0.5s ease-in-out;
}
.floatingEvent.show {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MuiStepLabel-label {
  margin-top: 0.2rem !important;
}

.show-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #8888887b;
  border-radius: 5px;
}

.all-transition {
  transition: all 0.3s ease;
}

.rotate-transition {
  transition: transform 0.3s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}

button {
  text-transform: none !important;
}

.ripple-container {
  display: none;
}

.btn {
  cursor: pointer;
}

.slick-track {
  margin-left: 0px !important;
}

.sliderDot {
  background-color: #dddddd;
}

.slick-active .sliderDot {
  background-color: #b8292f !important;
}

.slider-container {
  width: 100%;
  overflow: hidden;
}

.cms-content {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.cms-content a {
  color: #007bff;
}

.cms-content span {
  display: inline !important;
}

.cms-content p {
  display: block !important;
  margin-bottom: 0.5rem;
}

.cms-content img {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  display: inline !important;
}

.ribbon-left {
  width: 95px;
  height: 95px;
  overflow: hidden;
  position: absolute;
}

.ribbon-left::before,
.ribbon-left::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #eaad21;
}

.ribbon-left span {
  z-index: 20;
  position: absolute;
  display: block;
  width: 155px;
  padding: 1px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  line-height: 0.75rem;
  font-family: 'kittithada75' !important;
  font-weight: 500 !important;
}

/* top left*/
.ribbon-top-left {
  top: -5px;
  left: -5px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.text-stroke {
  -webkit-text-stroke: 1.5px 1.5px 0 #ffffff, -1.5px -1.5px 0 #ffffff,
    1.5px -1.5px 0 #ffffff, -1.5px 1.5px 0 #ffffff, 1.5px 1.5px 0 #ffffff;
  text-shadow: 1.5px 1.5px 0 #ffffff, -1.5px -1.5px 0 #ffffff,
    1.5px -1.5px 0 #ffffff, -1.5px 1.5px 0 #ffffff, 1.5px 1.5px 0 #ffffff;
}

.text-stroke-primary {
  -webkit-text-stroke: 1.5px 1.5px 0 #b8292f, -1.5px -1.5px 0 #b8292f,
    1.5px -1.5px 0 #b8292f, -1.5px 1.5px 0 #b8292f, 1.5px 1.5px 0 #b8292f;
  text-shadow: 1.5px 1.5px 0 #b8292f, -1.5px -1.5px 0 #b8292f,
    1.5px -1.5px 0 #b8292f, -1.5px 1.5px 0 #b8292f, 1.5px 1.5px 0 #b8292f;
}

.text-stroke-xl-primary {
  -webkit-text-stroke: 3px 3px 0 #b8292f, -3px -3px 0 #b8292f,
    3px -3px 0 #b8292f, -3px 3px 0 #b8292f, 3px 3px 0 #b8292f;
  text-shadow: 3px 3px 0 #b8292f, -3px -3px 0 #b8292f, 3px -3px 0 #b8292f,
    -3px 3px 0 #b8292f, 3px 3px 0 #b8292f;
}

.bundleList .slick-track {
  margin-left: auto !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.full-height .slick-slide > div {
  height: 95% !important;
}

.htmlContent dl,
.htmlContent ol,
.htmlContent ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.htmlContent ol,
.htmlContent ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.htmlContent ol {
  list-style-type: decimal;
}

.htmlContent ul {
  list-style-type: disc;
}

.htmlContent ul ul {
  list-style-type: circle;
}

.feedback-btn {
  background-color: #ffffff;
}

.excellent:hover {
  background-color: #ccebe8 !important;
}

.canbebetter:hover {
  background-color: #ffdede !important;
}

.feedback-img {
  background-color: #f1f1f1;
}

.excellent:hover > .feedback-img {
  background-color: #006e63 !important;
}

.canbebetter:hover > .feedback-img {
  background-color: #b8292f !important;
}

.cms-wrapper .table td,
.cms-wrapper .table th {
  border: 1px solid #cccccc;
  padding: 0.5rem;
}

.cms-wrapper img {
  display: unset;
}

.wistlistIcon:hover path {
  stroke: #d0011b;
}

.skushort {
  display: none;
}

.MuiSelect-select .delete-card-btn {
  display: none !important;
}

@media (max-width: 975.98px) {
  .skushort {
    display: inline;
  }
  .content-body {
    /* 100vh - CenterHeader - TopFooter - CenterFooter - BottomFooter */
    min-height: calc(40vh);
  }

  .h-content {
    height: calc(100vh - 2.5rem - 64px - 30rem - 3rem);
  }
}

@media (max-width: 767.98px) {
  .content-body {
    /* 100vh - CenterHeader - TopFooter - CenterFooter - BottomFooter */
    min-height: calc(40vh);
  }
}

@media (max-width: 584.98px) {
  .addtocart-text,
  .addtocart-text-xs {
    display: none !important;
  }

  .addtocart-plus,
  .addtocart-plus-xs {
    display: inline !important;
  }
}

@media (max-width: 566.98px) {
  .content-body {
    /* 100vh - CenterHeader - TopFooter - CenterFooter - BottomFooter */
    min-height: calc(40vh);
  }
}

@media (max-width: 479.98px) {
  .addtocart-text {
    display: -webkit-box !important;
  }

  .addtocart-text-xs {
    display: none !important;
  }

  .addtocart-plus {
    display: none !important;
  }

  .addtocart-plus-xs {
    display: inline !important;
  }

  .css-hp6j00-MuiButtonBase-root-MuiPaginationItem-root {
    margin: 0 0;
    min-width: 24px;
    height: 24px;
  }
}

@media (max-width: 383.98px) {
  .content-body {
    /* 100vh - CenterHeader - TopFooter - CenterFooter - BottomFooter */
    min-height: calc(40vh);
  }
}

@media (max-width: 355.98px) {
  .skushort {
    display: none;
  }
}

@media (max-width: 338.98px) {
  .text-policy {
    font-size: 0.875rem !important;
  }
}

table {
  border: 1px solid black;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid black;
}
ol {
  list-style-type: decimal !important;
  margin-left: 20px !important;
}
