.colorGradient {
  background: linear-gradient(258deg, rgb(131, 64, 158), rgb(76, 30, 100));
}

.topHeader {
  text-align: center;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  display: block;
}

.searchDesktop {
  background-color: #ebebeb !important;
  width: 100%;
  height: 100%;
  display: flex;
}

.navMenu {
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-bottom: solid 3px transparent;
  text-decoration: none;
  color: black;
}

.navMenu:hover {
  color: #b8292f;
  border-bottom: solid 3px #b8292f;
}

.bottomBarText {
  font-size: 0.75rem !important;
}

@media (max-width: 1099.98px) {
  .bottomBarText {
    font-size: 0.65rem !important;
  }
}

@media (min-width: 976px) {
  .topHeader {
    text-align: left;
    height: 1.5rem;
    display: flex;
  }
}

.separate {
  width: 2px;
  height: 11px;
  top: 10px;
  background-color: #bbbbbb;
  position: absolute;
  right: 0;
}
