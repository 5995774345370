.mainCategoryItem:hover {
  color: #b8292f;
  background-image: linear-gradient(
    to right,
    rgba(210, 210, 210, 0),
    rgb(210, 210, 210, 0.6)
  );
}

.lv2CategoryBox {
  /* height title + 7 items + 1 seemore */
  margin-top: 0.5rem;
  max-height: calc(1.25rem + 7rem + 1rem);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.lv2CategoryText {
  font-family: 'kittithada65' !important;
  font-weight: 500 !important;
  font-size: 0.83rem;
  height: 0.875rem;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lv2CategoryText:hover {
  font-size: 0.875rem;
}

.lv3CategoryText {
  font-size: 0.75rem;
  height: 1rem;
  line-height: 1rem;
  cursor: pointer;
  display: block;
}

.lv3CategoryText:hover {
  color: #b8292f;
}

.seeMore {
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1rem;
  color: #b8292f;
  cursor: pointer;
  display: block;
}

.seeMore:hover {
  text-decoration: underline;
}
