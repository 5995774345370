.menuMain {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -60px 0 0 -60px;
  list-style: none;
  font-size: 200%;
}

.menuButton {
  opacity: 0;
  z-index: -1;
}

.menuButtonTranspaent {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -60px 0 0 -60px;
  background: transparent !important;
  background-size: 100%;
  overflow: hidden;
  text-decoration: none;
}

.menuButton {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -60px 0 0 -60px;
  border-radius: 50%;
  background: #ffffff !important;
  background-size: 100%;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
}

.menuMain:not(:target) > a:first-of-type,
.menuMain:target > a:last-of-type {
  opacity: 1;
  z-index: 1;
}

.menuMain:target > a:first-of-type {
  opacity: 0;
}

.menuMain:not(:target) > .icon-plus:before,
.menuMain:target > .icon-minus:before {
  opacity: 1;
}

.menuItem {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 28%;
  line-height: 5px;
  top: 28%;
  margin: -40px 0 0 -40px;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(0px, 0px);
  transition: transform 500ms;
  z-index: -2;
  transition: 0.5s;
  cursor: pointer;
}

.menuItem:hover {
  opacity: 0.8;
}

.menuItem a {
  color: #fff;
  text-decoration: none;
}

.menuMain:target > .menuItem:nth-child(3) {
  transform: rotate(-30deg) translateY(-75px) rotate(40deg);
  transition-delay: 0.1s;
}

.menuMain:target > .menuItem:nth-child(4) {
  transform: rotate(-95deg) translateY(-75px) rotate(85deg);
  transition-delay: 0s;
}
